import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import { Link, graphql } from 'gatsby'

import get from 'lodash/get'
import { rhythm } from '../utils/typography'

class BlogPosts extends React.Component {
  render() {
      const posts = this.props.blogPosts;
      
      return (
        posts.map(({ node }) => {        
        const title = get(node, 'frontmatter.title') || node.fields.slug
        return (  <div key={node.fields.slug}>
          <h3
            style={{
              marginBottom: rhythm(1 / 4),
            }}
          >
            <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
              {title}
            </Link>
          </h3>
          <small>{node.frontmatter.date}</small>
          <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </div>)
    }))
  }
}

export default BlogPosts
